<template>
    <div class="material-box" :class="source ==='massSend'?'':'setWidth'">
        <!-- <div class="material-title" v-if="source !=='massSend'">
            <span>素材中心</span>
        </div> -->
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="iconfont guoran-tongyichicun-sucaizhongxin"></i>
                <span class="page-name">{{$t('materialSelection.title')}}</span>
            </div>
        </page-top-header>
        <div class="material-content">
            <el-tabs v-model="currentMaterialType" id="material-nav" tab-position="top" @tab-click="handleClick">
                <el-tab-pane :label="$t('materialSelection.imageAndText')" name="NEWS">
                    <div class="article-box">
                        <div class="article-box-title">
                            <el-input :placeholder="$t('common.inputPlaceholder')" v-model="articalSearchInput" class="input-with-select"
                                      size="small"
                                      @keyup.enter.native="loadMaterialList" @input="valueNull">
                                <el-button slot="append" icon="el-icon-search" @click="loadMaterialList"></el-button>
                            </el-input>
                        </div>
                        <div class="article-box-content" :class="source ==='massSend'?'setHeight':''">
                            <div class="article-item-add" @click="openMaterialNews">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>{{$t('materialSelection.addMaterial')}}</span>
                            </div>
                            <div class="article-item"
                                 :class="currentSelectedDiv==item.id&&source ==='massSend'?'article-item-selected':''"
                                 v-for="(item,index) in currentMaterialList" :key="index"
                                 @click="groupClick(index,'first',item)"
                                 @mouseenter="changShowOperation(index)" v-on:mouseleave="changShowOperation()">
                                <div class="article-item-image">
                                    <el-image
                                            style="width: 100%; height: 100px"
                                            fit="cover"
                                            :src="item.content.cover"
                                            v-if="item.content.cover"
                                    >

                                    </el-image>
                                    <div
                                            style="
                                                background-color: #F5F7FA;
                                                color: #C0C4CC;
                                                height: 90px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;"
                                            v-if="!item.content.cover"
                                    >{{$t('materialSelection.noCover')}}
                                    </div>
                                </div>
                                <div class="article-item-title">
                                    {{ item.content.title }}
                                </div>
                                <div class="article-item-desc">
                                    {{ item.content.summary }}
                                </div>
                                <div class="article-item-operation" v-show="showTextOperation === index">
                                    <div class="date">{{ new Date(item.updateTime).Format("yyyy-MM-dd") }}</div>
                                    <div class="button">
                                        <i class="el-icon-view" @click="copeUrl(item.content.url,item)"></i>
                                        <i class="el-icon-edit" @click="openarticleDrawer(item)"
                                           v-if="source==='material'"></i>
                                        <i class="el-icon-delete" @click="deleteMateria(item.id,'link')"
                                           v-if="source==='material'"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('materialSelection.externalLink')" name="LINK_NEWS">
                    <div class="article-box">
                        <div class="article-box-title">
                            <el-input :placeholder="$t('common.inputPlaceholder')" v-model="articalSearchInput" class="input-with-select"
                                      size="small"
                                      @keyup.enter.native="loadMaterialList" @input="valueNull">
                                <el-button slot="append" icon="el-icon-search" @click="loadMaterialList"></el-button>
                            </el-input>
                        </div>
                        <div class="article-box-content" :class="source ==='massSend'?'setHeight':''">
                            <div class="article-item-add" @click="openMaterialNewsLink">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>{{$t('materialSelection.addMaterial')}}</span>
                            </div>
                            <div class="article-item"
                                 :class="currentSelectedDiv==item.id&&source ==='massSend'?'article-item-selected':''"
                                 v-for="(item,index) in currentMaterialList" :key="index"
                                 @click="groupClick(index,'first',item)"
                                 @mouseenter="changShowOperation(index)" v-on:mouseleave="changShowOperation()">
                                <div class="article-item-image">
                                    <el-image style="width: 100%; height: 100px" fit="cover" :src="item.content.cover"
                                              v-if="item.content.cover"></el-image>
                                    <div
                                            style="
                                                background-color: #F5F7FA;
                                                color: #C0C4CC;
                                                height: 90px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;"
                                            v-if="!item.content.cover"
                                    >{{$t('materialSelection.noCover')}}
                                    </div>
                                </div>
                                <div class="article-item-title">
                                    {{ item.content.title }}
                                </div>
                                <div class="article-item-desc">
                                    {{ item.content.summary }}
                                </div>
                                <div class="article-item-operation" v-show="showTextOperation === index">
                                    <div class="date">{{ new Date(item.updateTime).Format("yyyy-MM-dd") }}</div>
                                    <div class="button">
                                        <i class="el-icon-view" @click="copeLinkUrl(item.content.url,item)"></i>
                                        <i class="el-icon-edit" @click="openMaterialNewsLinkDrawer(item)"
                                           v-if="source==='material'"></i>
                                        <i class="el-icon-delete" @click="deleteMateria(item.id,'linknews')"
                                           v-if="source==='material'"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('materialSelection.text')" name="TEXT">
                    <div class="text-box">
                        <div class="text-box-title">
                            <el-input :placeholder="$t('common.inputPlaceholder')" v-model="articalSearchInput" class="input-with-select"
                                      size="small"
                                      @keyup.enter.native="loadMaterialList" @input="valueNull">
                                <el-button slot="append" icon="el-icon-search" @click="loadMaterialList"></el-button>
                            </el-input>
                        </div>
                        <div class="text-box-content" :class="source ==='massSend'?'setHeight':''">
                            <div class="text-item-add" @click="openTextDialog">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>{{$t('materialSelection.addMaterial')}}</span>
                            </div>
                            <div class="text-item"
                                 :class="currentSelectedDiv==item.id  &&source ==='massSend'?'text-item-selected':''"
                                 @click="groupClick(index,'second',item)" v-for="(item,index) in currentMaterialList"
                                 :key="index" @mouseenter="changShowOperation(index)"
                                 v-on:mouseleave="changShowOperation()">
                                <div class="text-item-title">
                                    {{ item.content.content }}
                                </div>
                                <div class="text-item-operation"
                                     v-show="showTextOperation === index&&source==='material'">
                                    <div class="date">{{ new Date(item.updateTime).Format("yyyy-MM-dd") }}</div>
                                    <div class="button">
                                        <i class="el-icon-copy-document" @click="doCopy(item.content.content)"></i>
                                        <i class="el-icon-edit" @click="openMaterialText(item,'text')"></i>
                                        <i class="el-icon-delete" @click="deleteMateria(item.id,'text')"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('materialSelection.image')" name="IMAGE">
                    <div class="picture-box">
                        <div class="picture-box-title">
                            <el-input :placeholder="$t('common.inputPlaceholder')" v-model="articalSearchInput" class="input-with-select"
                                      size="small"
                                      @keyup.enter.native="loadMaterialList" @input="valueNull">
                                <el-button slot="append" icon="el-icon-search" @click="loadMaterialList"></el-button>
                            </el-input>
                        </div>
                        <div class="picture-box-content" :class="source ==='massSend'?'setHeight':''">
                            <div class="picture-item-add" @click="openPictureDialog ">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>{{$t('materialSelection.addMaterial')}}</span>
                            </div>
                            <div class="picture-item"
                                 :class="currentSelectedDiv==item.id  &&source ==='massSend' ?'picture-item-selected':''"
                                 @click="groupClick(index,'third',item)" v-for="(item,index) in currentMaterialList"
                                 :key="index" @mouseenter="changShowOperation(index)"
                                 v-on:mouseleave="changShowOperation()">
                                <div class="picture-item-image">
                                    <!-- <el-image
                                                      style="width: auto;height:200px"
                                                      fit="cover"
                                                      :preview-src-list="[item.content.url]"
                                                      :src="item.content.url"></el-image>-->

                                    <img
                                            style="width: 200px;height: 200px;object-fit: contain"
                                            :src="item.content.url"
                                    />

                                </div>
                                <div class="picture-item-name" v-show="showTextOperation !== index">{{ item.content.name
                                    }}
                                </div>
                                <div class="picture-item-operation" v-show="showTextOperation === index">
                                    <div class="date">{{ new Date(item.updateTime).Format("yyyy-MM-dd") }}</div>
                                    <div class="button">
                                        <i class="el-icon-view" @click="viewImage(item)"></i>
                                        <el-link
                                                :href="item.content.url" target="_blank"
                                                :underline="false"
                                                v-if="source==='material'"
                                        ><i class="el-icon-download"></i></el-link>
                                        <i
                                                class="el-icon-edit"
                                                @click="openMaterialText(item,'image')"
                                                v-if="source==='material'"
                                        ></i>
                                        <i
                                                class="el-icon-delete"
                                                @click="deleteMateria(item.id,'image')"
                                                v-if="source==='material'"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('materialSelection.video')" name="VIDEO">
                    <div class="picture-box">
                        <div class="picture-box-title">
                            <el-input :placeholder="$t('common.inputPlaceholder')" v-model="articalSearchInput" class="input-with-select"
                                      size="small"
                                      @keyup.enter.native="loadMaterialList" @input="valueNull">
                                <el-button slot="append" icon="el-icon-search" @click="loadMaterialList"></el-button>
                            </el-input>
                        </div>
                        <div class="picture-box-content" :class="source ==='massSend'?'setHeight':''">
                            <div class="picture-item-add" @click="openVideoDialog">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>{{$t('materialSelection.addMaterial')}}</span>
                            </div>
                            <div class="picture-item"
                                 :class="currentSelectedDiv==item.id &&source ==='massSend'?'picture-item-selected':''"
                                 @click="groupClick(index,'fourth',item)" v-for="(item,index) in currentMaterialList"
                                 :key="index"
                                 @mouseenter="changShowOperation(index)" v-on:mouseleave="changShowOperation()">
                                <div class="picture-item-image"
                                     style="display:flex;flex-direction: column;align-items: center;justify-content: center;background-color:#f0f4fc;height:200px">
                                    <!-- <video :src="item.content.url" controls class="video"
                                                      width="100%" height="270" style="margin-top:-79px"></video> -->
                                    <el-dialog
                                            title :visible.sync="dialogPlay"
                                            width="30%"
                                            :close-on-click-modal="false"
                                            @close="closeDialog"
                                            append-to-body
                                    >
                                        <video :src="videoUrl" controls autoplay class="video"
                                               width="100%"></video>
                                    </el-dialog>
                                    <em class="el-icon-video-play" style="font-size:36px;color:#366AFF;cursor: pointer;"
                                        @click="playVideo(item.content.url)"></em>
                                </div>
                                <div class="picture-item-name" v-show="showTextOperation != index||source!=='material'">
                                    {{ item.content.name }}
                                </div>
                                <div class="picture-item-operation"
                                     v-show="showTextOperation === index&&source==='material'">
                                    <div class="date">{{ new Date(item.updateTime).Format("yyyy-MM-dd") }}</div>
                                    <div class="button">
                                        <el-link :href="item.content.url" target="_blank" :underline="false"><i
                                                class="el-icon-download"></i></el-link>
                                        <i class="el-icon-edit" @click="openMaterialText(item,'video')"></i>
                                        <i class="el-icon-delete" @click="deleteMateria(item.id,'video')"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('materialSelection.file')" name="FILE">
                    <div class="picture-box">
                        <div class="picture-box-title">
                            <el-input :placeholder="$t('common.inputPlaceholder')" v-model="articalSearchInput" class="input-with-select"
                                      size="small"
                                      @keyup.enter.native="loadMaterialList" @input="valueNull">
                                <el-button slot="append" icon="el-icon-search" @click="loadMaterialList"></el-button>
                            </el-input>
                        </div>
                        <div class="picture-box-content" :class="source ==='massSend'?'setHeight':''">
                            <div class="picture-item-add" @click=" openFileDialog">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>{{$t('materialSelection.addMaterial')}}</span>
                            </div>
                            <div class="picture-item"
                                 :class="currentSelectedDiv==item.id &&source ==='massSend'?'picture-item-selected':''"
                                 @click="groupClick(index,'sixth',item)" v-for="(item,index) in currentMaterialList"
                                 :key="index"
                                 @mouseenter="changShowOperation(index)" v-on:mouseleave="changShowOperation()">
                                <div class="picture-item-image">
                                    <el-image
                                            style="width: auto;height:200px"
                                            fit="fill"
                                            src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-office-website/scrm/preview-img.jpg"></el-image>
                                </div>
                                <div class="picture-item-name" v-show="(showTextOperation != index)">{{
                                    item.content.name }}
                                </div>
                                <div class="picture-item-operation" v-show="showTextOperation === index">
                                    <div class="date">{{ new Date(item.updateTime).Format("yyyy-MM-dd") }}</div>
                                    <div class="button">
                                        <el-link :href="item.content.url" target="_blank" :underline="false"><i
                                                class="el-icon-download"></i></el-link>
                                        <i
                                                class="el-icon-edit"
                                                @click="openMaterialText(item,'file')"
                                                v-if="source==='material'"
                                        ></i>
                                        <i
                                                class="el-icon-delete"
                                                @click="deleteMateria(item.id,'file')"
                                                v-if="source==='material'"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <!--        <el-tab-pane label="音频" name="VOICE" v-if="source==='material'">
                          <div class="picture-box">
                            <div class="picture-box-title">
                              <el-input placeholder="请输入内容" v-model="articalSearchInput" class="input-with-select" size="small"
                                        @keyup.enter.native="loadMaterialList" @input="valueNull">
                                <el-button slot="append" icon="el-icon-search" @click="loadMaterialList"></el-button>
                              </el-input>
                            </div>
                            <div class="picture-box-content">
                              <div class="picture-item-add" @click="openVoiceDialog ">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>添加素材</span>
                              </div>
                              <div class="picture-item"
                                   :class="currentSelectedDiv==item.id &&source ==='massSend'?'picture-item-selected':''"
                                   @click="groupClick(index,'seventh',item)" v-for="(item,index) in currentMaterialList" :key="index"
                                   @mouseenter="changShowOperation(index)"
                                   v-on:mouseleave="changShowOperation()">
                                <div class="picture-item-image"
                                     style="display:flex;flex-direction: column;align-items: center;justify-content: center;background-color:#f0f4fc;height:200px">
                                  <em :id="index +'play'"
                                      class="el-icon-video-play"
                                      style="font-size:36px;color:#366AFF;cursor: pointer;"
                                      @click="listenAudio(index)"

                                  ></em>
                                  <em
                                      :id="index +'stop'"
                                      class="el-icon-video-pause"
                                      style="display:none;font-size:36px;color:#366AFF;cursor: pointer;"
                                      @click="stopAudio(index)"
                                  ></em>
                                </div>
                                <div class="picture-item-name" style="margin-top:10px" v-show="(showTextOperation != index)">
                                  {{ item.content.name }}
                                </div>
                                <div class="picture-item-operation" v-show="showTextOperation === index">
                                  <div class="date">{{ new Date(item.updateTime).Format('yyyy-MM-dd') }}</div>
                                  <div class="button">
                                    <el-link :href="item.content.url" target="_blank" :underline="false"><i
                                        class="el-icon-download"></i></el-link>
                                    <i class="el-icon-edit" @click="openMaterialText(item,'voice')"></i>
                                    <i class="el-icon-delete" @click="deleteMateria(item.id,'voice')"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-tab-pane>-->
            </el-tabs>
        </div>
        <!-- 图文信息编辑抽屉 -->
        <el-drawer
                :title="addMaterialTitle"
                :visible.sync="articleDrawer"
                direction="rtl"
                size="70%"
                ref="drawer"
                :modal="false"
                :wrapperClosable="false"
        >
            <div class="template-config">
                <div class="template-config-box">
                    <div class="template-config-title">
                        <div class="title-text">
                            <el-input
                                    type="text"
                                    :placeholder="$t('materialSelection.pleaseEnterTitle')"
                                    v-model="currentMaterialNews.content.title"
                                    maxlength="40"
                                    show-word-limit
                            ></el-input>
                        </div>
                        <div class="author">
                            <el-input
                                    type="text"
                                    :placeholder="$t('materialSelection.author')"
                                    v-model="currentMaterialNews.content.author"
                                    maxlength="10"
                                    show-word-limit
                            ></el-input>
                        </div>
                    </div>
                    <div class="template-config-content">
                        <ckeditor
                                :editor="editorInline"
                                v-model="currentMaterialNews.content.content"
                                :config="editorConfig"
                                @ready="onReady"
                        ></ckeditor>
                    </div>
                    <div class="template-config-other">
                        <div class="image">
                            <el-upload
                                    action="/api/oss/public?token=8c98087dfd2d48f856d8c95c09115def"
                                    list-type="text"
                                    :file-list="fileList"
                                    accept=".jpg,.png"
                                    :on-remove="(file,fileList)=>currentMaterialNews.content.cover=''"
                                    :on-success="imageFileUpload"
                                    :on-change="handleChangePic"
                                    :http-request="aliyunOssRequestMaterialNews"
                                    :on-preview="(file)=>dialogImageUrl=file.url"
                                    style="display: flex;justify-content: center;align-items: center;"
                            >
                                <div>
                                    <div :style="currentMaterialNews.content.cover===''?'width:40vw':'width:80px'"
                                         style="margin-right: 16px;flex: none">
                                        <i class="el-icon-plus avatar-uploader-icon"></i>
                                        <span v-if="currentMaterialNews.content.cover===''">{{$t('materialSelection.addCover')}}</span>
                                        <span v-else>{{$t('materialSelection.reUpload')}}</span>
                                    </div>
                                </div>
                            </el-upload>
                        </div>
                        <div class="desc">
                            <el-input
                                    type="textarea"
                                    :placeholder="$t('materialSelection.des')"
                                    v-model="currentMaterialNews.content.summary"
                                    maxlength="60"
                                    show-word-limit
                            ></el-input>
                        </div>
                    </div>

                </div>
                <div class="template-preview">
                    <template>
                        <el-tabs v-model="previewActiveName" id="material-right">
                            <el-tab-pane :label="$t('materialSelection.messagePushPreview')" name="first">
                                <div class="template-preview-content">
                                    <div class="template-preview-article-title">
                                        <i class="el-icon-arrow-left"></i>
                                        <span>{{isE() ? 'eBot' : $t('materialSelection.aiBot')}}</span>
                                        <i class="el-icon-s-custom"></i>
                                    </div>
                                    <div class="template-preview-message-content">
                                        <div class="push-message-box">
                                            <div class="image">
                                                <el-image
                                                        style="width:100%;height:120px"
                                                        :src="currentMaterialNews.content.cover"
                                                        fit="cover"
                                                        v-if="currentMaterialNews.content.cover!==''"
                                                ></el-image>
                                                <div
                                                        style="
                                                background-color: #F5F7FA;
                                                color: #C0C4CC;
                                                height: 90px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;"
                                                        v-if="currentMaterialNews.content.cover===''"
                                                >{{$t('materialSelection.noCover')}}
                                                </div>
                                            </div>
                                            <div class="title">
                                                {{ currentMaterialNews.content.title }}
                                            </div>
                                            <div class="desc">
                                                {{ currentMaterialNews.content.summary }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('materialSelection.messageDetailPreview')" name="second">
                                <div class="template-preview-content">
                                    <div class="template-preview-article-title">
                                        <i class="el-icon-close"></i>
                                        <span>{{ currentMaterialNews.content.title }}</span>
                                        <i class="el-icon-more"></i>
                                    </div>
                                    <div class="template-preview-article-content">
                                        <div class="article-content-title">
                                            {{ currentMaterialNews.content.title }}
                                        </div>
                                        <div class="article-content-author">
                                            <span>{{ currentMaterialNews.content.author }}</span>
                                            <span>{{ articelAddDate }}</span>
                                        </div>
                                        <div class="article-content-body-con">
                                            <p v-html="currentMaterialNews.content.content"></p>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </template>
                </div>
            </div>
            <div class="article-template-footer">
                <el-button @click="articleDrawer = false">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveCurrentNews">{{$t('common.submit')}}</el-button>
            </div>
        </el-drawer>
        <!--外链图文抽屉-->
        <el-drawer
                :title="addMaterialTitle"
                :visible.sync="articleLinkDrawer"
                direction="rtl"
                size="70%"
                ref="drawer"
                :modal="false"
                :wrapperClosable="false"
        >
            <div class="template-config">
                <div class="template-config-box">
                    <div class="template-config-title">
                        <div class="link-title-text">
                            <el-input
                                    type="text"
                                    :placeholder="$t('materialSelection.pleaseEnterTitle')"
                                    v-model="currentMaterialLinkNews.content.title"
                                    maxlength="40"
                                    show-word-limit
                            ></el-input>
                        </div>
                        <!--<div class="author">
                                      <el-input
                                              type="text"
                                              placeholder="作者"
                                              v-model="currentMaterialNews.content.author"
                                              maxlength="10"
                                              show-word-limit
                                      ></el-input>
                                  </div>-->
                    </div>
                    <div class="template-link">
                        <el-input
                                type="text"
                                :placeholder="$t('materialSelection.messageLinkPla')"
                                v-model="currentMaterialLinkNews.content.url"
                        ></el-input>
                    </div>
                    <div class="template-config-other">
                        <div class="image">
                            <el-upload
                                    action="/api/oss/public?token=8c98087dfd2d48f856d8c95c09115def"
                                    list-type="text"
                                    :file-list="fileList"
                                    accept=".jpg,.png"
                                    :on-remove="(file,fileList)=>currentMaterialLinkNews.content.cover=''"
                                    :on-success="imageLinkUpload"
                                    :on-change="handleChangePic"
                                    :http-request="aliyunOssRequestMaterialNews"
                                    :on-preview="(file)=>dialogImageUrl=file.url"
                                    style="display: flex;justify-content: center;align-items: center;"
                            >
                                <div>
                                    <div :style="currentMaterialLinkNews.content.cover===''?'width:40vw':'width:80px'"
                                         style="margin-right: 16px;flex: none">
                                        <i class="el-icon-plus avatar-uploader-icon"></i>
                                        <span v-if="currentMaterialLinkNews.content.cover===''">{{$t('materialSelection.addCover')}}</span>
                                        <span v-else>{{$t('materialSelection.reUpload')}}</span>
                                    </div>
                                </div>
                            </el-upload>
                        </div>
                        <div class="desc">
                            <el-input
                                    type="textarea"
                                    :placeholder="$t('materialSelection.des')"
                                    v-model="currentMaterialLinkNews.content.summary"
                                    maxlength="60"
                                    show-word-limit
                            ></el-input>
                        </div>
                    </div>

                </div>
                <div class="template-preview">
                    <template>
                        <el-tabs v-model="previewLinkActiveName" id="material-right-link">
                            <el-tab-pane :label="$t('materialSelection.messagePushPreview')" name="first">
                                <div class="template-preview-content">
                                    <div class="template-preview-article-title">
                                        <i class="el-icon-arrow-left"></i>
                                        <span>{{isE() ? 'eBot' : $t('materialSelection.aiBot')}}</span>
                                        <i class="el-icon-s-custom"></i>
                                    </div>
                                    <div class="template-preview-message-content">
                                        <div class="push-message-box">
                                            <div class="image">
                                                <el-image
                                                        style="width:100%;height:120px"
                                                        :src="currentMaterialLinkNews.content.cover"
                                                        fit="cover"
                                                        v-if="currentMaterialLinkNews.content.cover!==''"
                                                ></el-image>
                                                <div
                                                        style="
                                                background-color: #F5F7FA;
                                                color: #C0C4CC;
                                                height: 90px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;"
                                                        v-if="currentMaterialLinkNews.content.cover===''"
                                                >{{$t('materialSelection.noCover')}}
                                                </div>
                                            </div>
                                            <div class="title">
                                                {{ currentMaterialLinkNews.content.title }}
                                            </div>
                                            <div class="desc">
                                                {{ currentMaterialLinkNews.content.summary }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </template>
                </div>
            </div>
            <div class="article-template-footer">
                <el-button @click="articleLinkDrawer = false">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="saveCurrentNewsLink">{{$t('common.submit')}}</el-button>
            </div>
        </el-drawer>
        <!-- 添加文本素材 -->
        <el-dialog
                :title="materialTitle"
                :visible.sync="textDialogVisible"
                :close-on-click-modal="false"
                append-to-body
                width="30%">
            <el-input
                    type="textarea"
                    :placeholder="$t('common.inputPlaceholder')"
                    v-model="currentMaterialText.content.content"
                    rows="5"
                    maxlength="100"
                    show-word-limit
            >
            </el-input>
            <span slot="footer" class="dialog-footer">
            <el-button @click="textDialogVisible = false">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="saveCurrentText">{{$t('common.confirm')}}</el-button>
        </span>
        </el-dialog>
        <!-- 添加图片素材 -->
        <el-dialog
                :title="materialTitle"
                :visible.sync="pictureDialogVisible"
                :close-on-click-modal="false"
                append-to-body
                class="addImage"
                @close="closeDialogPic"
                width="30%"

        >
            <div v-loading="loadingUpload">
                <el-form label-width="80px">
                    <el-form-item :label="$t('materialSelection.name')">
                        <el-input v-model="currentMaterialImage.content.name" :placeholder="$t('common.inputPlaceholder')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('materialSelection.image')">
                        <!-- <el-upload
                        action="guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com"
                        list-type="picture-card"
                        :show-file-list="false"
                        :on-preview="handlePictureCardPreview"
                        :http-request="aliyunOssRequest"
                        :on-success="(res)=>currentMaterialImage.content.url=res.url"
                        :on-remove="(file)=>currentMaterialImage.content.url=''">
                            <img v-if="currentMaterialImage.content.url"
                            :src="currentMaterialImage.content.url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload> -->
                        <el-upload
                                action="guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com"
                                :http-request="aliyunOssRequestMaterialNews"
                                :on-success="pictureUploadSuccess"
                                list-type="text"
                                :limit="1"
                                accept=".jpg,.png"
                                :file-list="fileList"
                                :on-remove="handleRemove"
                                ref="my-upload"
                        >
                            <!-- <div v-if="currentMaterialImage.content.url" class="file-list-box">
                                 <div class="file-name">
                                     {{currentMaterialImage.content.name}}
                                 </div>
                                 <div class="file-status">
                                     <i style="color:green" class="el-icon-circle-check"></i>
                                 </div>
                             </div>-->
                            <el-button v-if="showUplodaBtn" type="primary">{{$t('materialSelection.uploadFile')}}<i
                                    class="el-icon-upload el-icon--right"></i>

                            </el-button>
                        </el-upload>
                        <el-link type="primary"
                                 @click="customImageDialogVisible=true"
                                 :underline="false"
                                 v-if="currentMaterialImage.content.url"
                        >{{$t('materialSelection.customImage')}}
                        </el-link>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialogPic">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="saveCurrentImage">{{$t('common.confirm')}}</el-button>
        </span>
            </div>

        </el-dialog>

        <!-- 调加视频素材 -->
        <el-dialog
                :title="materialTitle"
                :visible.sync="videoDialogVisible"
                append-to-body
                class="addImage"
                @close="closeDialogPic"
                :close-on-click-modal="false"
                width="30%">
            <div v-loading="loadingUpload">
                <el-form label-width="80px">
                    <el-form-item :label="$t('materialSelection.name')">
                        <el-input v-model="currentMaterialVideo.content.name" :placeholder="$t('common.inputPlaceholder')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('materialSelection.video')">
                        <!-- <el-upload
                        action="guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com"
                        :show-file-list="false"
                        :http-request="aliyunOssRequest"
                        :on-success="(res)=>currentMaterialVideo.content.url=res.url"
                        >
                            <video v-if="currentMaterialVideo.content.url" width="200px"
                            :src="currentMaterialVideo.content.url" class="avatar"></video>
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload> -->
                        <el-upload
                                action="guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com"
                                :http-request="aliyunOssRequestVideo"
                                :on-success="videoUploadSuccess"
                                list-type="text"
                                :limit="1"
                                accept=".mp4"
                                :on-remove="handleRemove"
                                :file-list="fileList"
                        >
                            <!-- <div v-if="currentMaterialVideo.content.url" class="file-list-box">
                                 <div class="file-name">
                                     {{currentMaterialVideo.content.name}}
                                 </div>
                                 <div class="file-status">
                                     <i style="color:green" class="el-icon-circle-check"></i>
                                 </div>
                             </div>-->
                            <el-button v-if="showUplodaBtn" type="primary">{{$t('materialSelection.uploadFile')}}<i
                                    class="el-icon-upload el-icon--right"></i>
                            </el-button>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialogPic">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="saveCurrentVideo">{{$t('common.confirm')}}</el-button>
        </span>
            </div>

        </el-dialog>

        <!-- 调加文件素材 -->
        <el-dialog
                :title="materialTitle"
                :visible.sync="fileDialogVisible"
                class="addImage"
                @close="closeDialogPic"
                :close-on-click-modal="false"
                append-to-body
                width="30%">
            <div v-loading="loadingUpload">
                <el-form label-width="80px">
                    <el-form-item :label="$t('materialSelection.name')">
                        <el-input v-model="currentMaterialFile.content.name" :placeholder="$t('common.inputPlaceholder')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('materialSelection.file')">
                        <el-upload
                                action="guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com"
                                :http-request="aliyunOssRequest"
                                :on-success="fileUploadSuccess"
                                list-type="text"
                                :limit="1"
                                :on-remove="handleRemove"
                                :file-list="fileList"
                        >
                            <!--<div v-if="currentMaterialFile.content.url" class="file-list-box">
                                <div class="file-name">
                                    {{currentMaterialFile.content.name}}
                                </div>
                                <div class="file-status">
                                    <i style="color:green" class="el-icon-circle-check"></i>
                                </div>
                            </div>-->
                            <el-button v-if="showUplodaBtn" type="primary">{{$t('materialSelection.uploadFile')}}<i
                                    class="el-icon-upload el-icon--right"></i>
                            </el-button>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialogPic">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="saveCurrentFile">{{$t('common.confirm')}}</el-button>
        </span>
            </div>

        </el-dialog>

        <!-- 调加音频素材 -->
        <el-dialog
                :title="materialTitle"
                :visible.sync="voiceDialogVisible"
                class="addImage"
                :close-on-click-modal="false"
                @close="closeDialogPic"
                append-to-body
                width="30%">
            <div v-loading="loadingUpload">
                <el-form label-width="80px">
                    <el-form-item :label="$t('materialSelection.name')">
                        <el-input v-model="currentMaterialVoice.content.name" :placeholder="$t('common.inputPlaceholder')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('materialSelection.voice')">
                        <el-upload
                                action="guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com"
                                :http-request="aliyunOssRequestVoice"
                                :on-success="voiceUploadSuccess"
                                list-type="text"
                                :limit="1"
                                accept=".amr,.mp3"
                                :on-remove="handleRemove"
                                :file-list="fileList"
                        >
                            <!--<div v-if="currentMaterialVoice.content.url" class="file-list-box">
                                <div class="file-name">
                                    {{currentMaterialVoice.content.name}}
                                </div>
                                <div class="file-status">
                                    <i style="color:green" class="el-icon-circle-check"></i>
                                </div>
                            </div>-->
                            <el-button v-if="showUplodaBtn" type="primary">{{$t('materialSelection.uploadVoiceFile')}}<i
                                    class="el-icon-upload el-icon--right"></i>
                            </el-button>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialogPic">{{$t('common.cancel')}}</el-button>
            <el-button type="primary" @click="saveCurrentVoice">{{$t('common.confirm')}}</el-button>
        </span>
            </div>

        </el-dialog>


        <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <el-dialog
                :title="$t('materialSelection.customImage')"
                v-if="customImageDialogVisible"
                :visible="customImageDialogVisible"
                width="80%"
                class="custom-dialog"
                :close-on-click-modal="false"
                append-to-body
        >
            <template slot="title">
                <div class="custom-header">
                    <span>{{$t('materialSelection.customImage')}}</span>
                    <div>
                        <el-button @click="customImageDialogVisible=false" size="small">{{$t('common.cancel')}}</el-button>
                        <el-button type="primary" @click="finshedCustomImg" size="small">{{$t('common.finish')}}</el-button>
                    </div>
                </div>
            </template>
            <CustomImage
                    :background="currentMaterialImage.content.url"
                    ref="CustomImage"
                    :drawnComponents="currentMaterialImage.content.drawnComponents"
            ></CustomImage>
        </el-dialog>
        <div
                v-if="showPreview"
                style="
        width: 100vw;
        height: 100vh;
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
      "
        >
            <div
                    @click="showPreview = false"
                    style="
          color: white;
          font-size: 30px;
          padding: 30px 30px 15vh;
          text-align: right;
          cursor: pointer;
        "
            >
                X
            </div>
            <div style="display: flex;justify-content: center;align-items: center">
                <img
                        style="max-height: 70vh; max-width: 70vw"
                        :src="previewImageUrl"
                        alt
                        srcset
                />
            </div>

        </div>
    </div>
</template>

<script>
    import pageTopHeader from '../../../components/page-top-header.vue'
    import { upload, ossConfig } from "../utils/AliyunlssUtil";
    import Clipboard from "clipboard";
    import { MyCustomUploadAdapterPlugin } from "./ckeditor";
    import CKEDITOR from "ckeditor";
    import { redirectUri } from "../../../const/redirectUri";
    import CustomImage from "./CustomImage";

    export default {
        components: {
            Clipboard,
            CustomImage,
            pageTopHeader
        },
        data() {
            return {
                //当前选择的素材类型
                currentMaterialType: "NEWS",
                // 当前素材列表
                currentMaterialList: [],
                // 搜索关键字
                searchKeywords: "",
                // 当前图文素材
                currentMaterialNews: {
                    id: "",
                    content: {
                        author: "",
                        content: "",
                        cover: "",
                        ext: {},
                        summary: "",
                        title: "",
                        coverSize: "",
                    },
                },
                currentMaterialLinkNews: {
                    id: "",
                    content: {
                        author: "",
                        cover: "",
                        coverSize: 0,
                        summary: "",
                        title: "",
                        url: "",
                    },
                },
                // 当前文本素材
                currentMaterialText: {
                    id: "",
                    content: {
                        content: "",
                    },
                },
                currentMaterialImage: {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        size: 0,
                        ext: {},
                        drawnComponents: [],
                    },
                },
                videoDialogVisible: false,
                currentMaterialVideo: {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        description: "",
                        size: 0,
                        cover: "",
                        ext: {},
                    },
                },
                fileDialogVisible: false,
                currentMaterialFile: {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        size: 0,
                    },
                },
                voiceDialogVisible: false,
                currentMaterialVoice: {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        ext: {},
                        size: 0,
                    },
                },
                voiceFileList: [],
                articalSearchInput: "",
                isShowOperation: false,
                articleDrawer: false,
                articleLinkDrawer: false,
                articleTitleText: "",
                articleAuthor: "",
                articleDesc: "",
                articleBody: "",
                articelAddDate: "",
                previewActiveName: "first",
                previewLinkActiveName: "first",
                textDialogVisible: false,
                textarea: "",
                srcList: [
                    "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                ],
                isShowPictrueName: true,
                pictureDialogVisible: false,
                pictureForm: {
                    name: "",
                    pictureUrl: "",
                },
                videoUrl: "https://v-cdn.zjol.com.cn/276991.mp4",
                dialogPlay: false,
                textList: [
                    { id: "43423434424", title: "真劳模：凭一己之力为全公司干活", updatetime: "2021-04-03" },
                    {
                        id: "43423434424",
                        title: "真劳模：凭一己之力为全公司干活，任劳任怨且全年不休，AskBot了解一下真劳模：凭一己之力为全公司干活，任劳任怨且全年不休，AskBot了解一下真劳模：凭一己之力为全公司干活，任劳任怨且全年不休，AskBot了解一下",
                        updatetime: "2021-04-03",
                    },
                    { id: "43423434424", title: "真劳模：凭一己之", updatetime: "2021-04-03" },
                    { id: "43423434424", title: "真劳模：凭一己之力为全公司干活，任劳任怨且全年不休，AskBot了解一下", updatetime: "2021-04-03" },
                ],
                showTextOperation: "null",
                articleList: [
                    {
                        id: "43423434424",
                        title: "真劳模：凭一己之力为全公司干活公司干活公司干活公司干活",
                        updatetime: "2021-04-03",
                        desc: "中新网5月8日电 据杭州市富阳区人民政府新闻办公室官方微博消息，针对杭州野生动物世界三只未成年金钱豹外逃事件，联合调查组已经入驻，将彻查金钱豹外逃原因。目前，已对周边进行全面布控，搜索队正在进行拉网式搜索。",
                        author: "amao",
                        body: "",
                        imgUrl: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                    },
                    {
                        id: "43423434424",
                        title: "真劳模：凭一己之力为全公司干活",
                        updatetime: "2021-04-03",
                        desc: "中新网5月8日电 据杭州市富阳区人民政府新闻办公",
                        author: "",
                        body: "",
                        imgUrl: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                    },
                    {
                        id: "43423434424",
                        title: "真劳模：凭一己之力为全公司干活",
                        updatetime: "2021-04-03",
                        desc: "中新网5月8日电 据杭州市富阳区人民政府新闻办公",
                        author: "",
                        body: "",
                        imgUrl: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                    },
                    {
                        id: "43423434424",
                        title: "真劳模：凭一己之力为全公司干活",
                        updatetime: "2021-04-03",
                        desc: "中新网5月8日电 据杭州市富阳区人民政府新闻办公",
                        author: "",
                        body: "",
                        imgUrl: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                    },
                    {
                        id: "43423434424",
                        title: "真劳模：凭一己之力为全公司干活",
                        updatetime: "2021-04-03",
                        desc: "中新网5月8日电 据杭州市富阳区人民政府新闻办公",
                        author: "",
                        body: "",
                        imgUrl: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
                    },
                ],
                pictureList: [],
                videoList: [],
                fileList: [],
                audioList: [],
                palyingAudioUrl: "", //音频素材URL
                playingStutus: false, //音频播放状态
                playIndex: 0 + "play",
                dialogImageUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg", //图文素材缩略图上传URL
                dialogVisible: false,
                currentSelectedTabName: "null",
                currentSelectedDiv: "null",
                currentTabName: "",
                disabled: false,
                picFilelList: [],
                videoFilelList: [],
                fileFileList: [],
                editorConfig: {
                    extraPlugins: [MyCustomUploadAdapterPlugin],
                    toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "imageUpload",
                        "insertTable",
                        "mediaEmbed",
                        "Undo",
                        "Redo",

                    ],
                },
                editorInline: CKEDITOR.ClassicEditor,
                materialTitle: "",
                showPreview: false,
                previewImageUrl: "",
                addMaterialTitle: "添加图文素材",
                showUplodaBtn: true,
                loadingUpload: false,
                customImageDialogVisible: false,
            };
        },
        props: {
            pathUrl: {
                type: String,
                default() {
                    return "material";
                },
            },
            selectedGroupType: {
                type: String,
                default() {
                    return "";
                },
            },
            source: {
                type: String,
                default() {
                    return "material";
                },
            },
        },
        watch: {
            currentMaterialType(type) {
                this.currentMaterialList = [];
                this.searchKeywords = "";
                this.loadMaterialList();
            },
        },
        mounted() {
            this.loadMaterialList();
            this.currentSelectedDiv = "null";
            console.log("dynameic", this.pathUrl);
            console.log("source", this.source);
        },
        methods: {
            onReady(editor) {
                console.log("12345");
                /*editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                          console.log(new MyUploadAdapter( loader ))
                          return new MyUploadAdapter( loader );
                      }*/

            },
            doCopy(content) {
                let that = this;
                this.$copyText(content).then(function (e) {
                    that.$message({ message: "复制成功", type: "success" });
                }, function (e) {
                    alert("您的浏览器不允许复制");
                });
            },
            handleClick() {
                this.articalSearchInput = "";
            },
            beforeUploadPicture(file) {
                console.log(file);
                let size = file.size / 1024 / 1024 <= 2;
                let type = file.type.indexOf("image") !== -1;
                if (!type) {
                    this.$message({
                        message: this.$t('materialSelection.onlyUploadImage'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (!size) {
                    this.$message({
                        message: this.$t('materialSelection.uploadImageSize'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                return type && size;
            },
            voiceUploadSuccess(res, file) {
                console.log(res, file);
                this.currentMaterialVoice.content.url = res.url;
                if (this.currentMaterialVoice.content.name === "") {
                    this.currentMaterialVoice.content.name = file.name;
                }
                this.currentMaterialVoice.content.size = file.size;
                this.loadingUpload = false;
                //console.log(this.currentMaterialVoice);
            },
            fileUploadSuccess(res, file) {
                console.log(res, file);
                this.currentMaterialFile.content.url = res.url;
                if (this.currentMaterialFile.content.name === "") {
                    this.currentMaterialFile.content.name = file.name;
                }
                this.currentMaterialFile.content.size = file.size;
                console.log(this.currentMaterialFile);
                this.loadingUpload = false;
            },
            pictureUploadSuccess(res, file) {
                console.log(res, file);
                this.currentMaterialImage.content.url = res.url;
                if (this.currentMaterialImage.content.name === "") {
                    this.currentMaterialImage.content.name = file.name;
                }
                this.currentMaterialImage.content.size = file.size;
                this.currentMaterialImage.content.drawnComponents = [];
                this.loadingUpload = false;
            },
            removePicture() {
                this.currentMaterialImage.content.url = "";
                this.currentMaterialImage.content.name = "";
                this.currentMaterialImage.content.size = "";
                this.currentMaterialImage.content.drawnComponents = [];
                this.fileList = [];
            },
            videoUploadSuccess(res, file) {
                console.log(res, file);
                this.currentMaterialVideo.content.url = res.url;
                if (this.currentMaterialVideo.content.name === "") {
                    this.currentMaterialVideo.content.name = file.name;
                }
                this.currentMaterialVideo.content.size = file.size;
                this.loadingUpload = false;
            },
            imageFileUpload(res, file) {
                console.log(file);
                console.log(res);
                console.log(this.currentMaterialNews.content.cover);
                this.currentMaterialNews.content.cover = res.url;
                this.currentMaterialNews.content.coverSize = file.size;
                this.currentMaterialNews.content.ext.name = file.name;
                this.loadingUpload = false;
            },
            imageLinkUpload(res, file) {
                this.currentMaterialLinkNews.content.cover = res.url;
                this.currentMaterialLinkNews.content.coverSize = file.size;
            },
            handleChangePic(file, fileList) {
                console.log(fileList);
                if (fileList.length > 1) {
                    fileList.splice(0, 1);
                    this.fileList = fileList;
                }
                console.log(this.currentMaterialNews.content.cover);
            },
            deleteMateria(id, type) {
                let title = "";
                if (type === "link") {
                    title = this.$t('materialSelection.deleteLinkMsg');
                }
                if (type === "image") {
                    title = this.$t('materialSelection.deleteImageMsg');
                }
                if (type === "text") {
                    title = this.$t('materialSelection.deleteTextMsg');
                }
                if (type === "video") {
                    title = this.$t('materialSelection.deleteVideoMsg');
                }
                if (type === "file") {
                    title = this.$t('materialSelection.deleteFileMsg');
                }
                if (type === "voice") {
                    title = this.$t('materialSelection.deleteVoiceMsg');
                }
                if (type === "linknews") {
                    title = this.$t('materialSelection.deleteLinkNewMsg');
                }
                this.$confirm(title, this.$t('materialSelection.deleteTitle'), {
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    type: "warning",
                }).then(() => {
                    this.FetchDelete2("/dryer-api/material/" + id).then(res => {
                        this.$message({ message: this.$t('materialSelection.deleteSuccess'), type: "success" });
                        this.loadMaterialList();
                    });
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: this.$t('materialSelection.cancelDelete'),
                        duration: 2000,
                    });
                });
            },
            saveCurrentVoice() {
                if (this.currentMaterialVoice.content.name === "") {
                    this.$message({
                        message: this.$t('materialSelection.voiceName'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialVoice.content.url === "") {
                    this.$message({
                        message: this.$t('materialSelection.selectVoice'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialVoice.id == "") {
                    this.FetchPost("/dryer-api/material/voice", this.currentMaterialVoice.content).then(res => {
                        console.debug("create text response", res);
                        console.log("create text response", res);
                        this.$message({ message: this.$t('materialSelection.saveVoiceSuccess'), type: "success" });
                        this.loadMaterialList();
                        this.voiceDialogVisible = false;
                    });
                } else {
                    let postVoice = {
                        "ext": this.currentMaterialVoice.content.ext,
                        "name": this.currentMaterialVoice.content.name,
                        "size": this.currentMaterialVoice.content.size,
                        "url": this.currentMaterialVoice.content.url,
                    };
                    this.FetchPut("/dryer-api/material/voice/", this.currentMaterialVoice.id, postVoice).then(res => {
                        console.debug("update text response", res);
                        this.$message({ message: this.$t('materialSelection.updateVoiceSuccess'), type: "success" });
                        this.voiceDialogVisible = false;
                        this.loadMaterialList();
                    });
                }
            },
            saveCurrentFile() {
                if (this.currentMaterialFile.content.name === "") {
                    this.$message({
                        message: this.$t('materialSelection.fileName'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialFile.content.url === "") {
                    this.$message({
                        message: this.$t('materialSelection.selectFile'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialFile.id == "") {
                    this.FetchPost("/dryer-api/material/file", this.currentMaterialFile.content).then(res => {
                        console.debug("create text response", res);
                        this.$message({ message: this.$t('materialSelection.saveFileSuccess'), type: "success" });
                        this.loadMaterialList();
                        this.fileDialogVisible = false;
                    });
                } else {
                    let postFile = {
                        "ext": this.currentMaterialFile.content.ext,
                        "name": this.currentMaterialFile.content.name,
                        "size": this.currentMaterialFile.content.size,
                        "url": this.currentMaterialFile.content.url,
                    };
                    this.FetchPut("/dryer-api/material/file/", this.currentMaterialFile.id, postFile).then(res => {
                        console.debug("update text response", res);
                        this.$message({ message: this.$t('materialSelection.updateFileSuccess'), type: "success" });
                        this.fileDialogVisible = false;
                        this.loadMaterialList();
                    });
                }
            },
            saveCurrentVideo() {
                if (this.currentMaterialVideo.content.name === "") {
                    this.$message({
                        message: this.$t('materialSelection.videoName'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialVideo.content.url === "") {
                    this.$message({
                        message: this.$t('materialSelection.selectVideo'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialVideo.id == "") {
                    this.FetchPost("/dryer-api/material/video", this.currentMaterialVideo.content).then(res => {
                        console.debug("create text response", res);
                        this.$message({ message: this.$t('materialSelection.saveVideoSuccess'), type: "success" });
                        this.loadMaterialList();
                        this.videoDialogVisible = false;
                    });
                } else {
                    let postData = {
                        "cover": this.currentMaterialVideo.content.cover,
                        "description": this.currentMaterialVideo.content.description,
                        "ext": this.currentMaterialVideo.content.ext,
                        "name": this.currentMaterialVideo.content.name,
                        "size": this.currentMaterialVideo.content.size,
                        "url": this.currentMaterialVideo.content.url,
                    };
                    this.FetchPut("/dryer-api/material/video/", this.currentMaterialVideo.id, postData).then(res => {
                        console.debug("update text response", res);
                        this.$message({ message: this.$t('materialSelection.updateVideoSuccess'), type: "success" });
                        this.videoDialogVisible = false;
                        this.loadMaterialList();
                    });
                }
            },
            saveCurrentImage() {
                if (this.currentMaterialImage.content.name === "") {
                    this.$message({
                        message: this.$t('materialSelection.imageName'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialImage.content.url === "") {
                    this.$message({
                        message: this.$t('materialSelection.selectImage'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialImage.id == "") {
                    this.FetchPost("/dryer-api/material/image", this.currentMaterialImage.content).then(res => {
                        console.debug("create text response", res);
                        this.$message({ message: this.$t('materialSelection.saveImageSuccess'), type: "success" });
                        this.loadMaterialList();
                        this.pictureDialogVisible = false;
                    });
                } else {
                    let postImage = {
                        "ext": this.currentMaterialImage.content.ext,
                        "name": this.currentMaterialImage.content.name,
                        "size": this.currentMaterialImage.content.size,
                        "url": this.currentMaterialImage.content.url,
                        "drawnComponents": this.currentMaterialImage.content.drawnComponents,
                    };
                    this.FetchPut("/dryer-api/material/image/", this.currentMaterialImage.id, postImage).then(res => {
                        console.debug("update text response", res);
                        this.$message({ message: this.$t('materialSelection.updateImageSuccess'), type: "success" });
                        this.loadMaterialList();
                        this.pictureDialogVisible = false;
                    });
                }
            },
            openMaterialText(item, type) {
                this.fileList = [];
                this.showUplodaBtn = false;
                console.log(item);
                if (type == "text") {
                    this.currentMaterialText = JSON.parse(JSON.stringify(item));
                    this.textDialogVisible = true;
                    this.materialTitle = this.$t('materialSelection.editText');
                }
                if (type === "image") {
                    this.currentMaterialImage = JSON.parse(JSON.stringify(item));
                    if (this.currentMaterialImage.content.drawnComponents == null) {
                        this.currentMaterialImage.content.drawnComponents = [];
                    }
                    console.debug("open image", this.currentMaterialImage);
                    this.pictureDialogVisible = true;
                    this.materialTitle = this.$t('materialSelection.editImage');
                    if (item.content.url !== "") {
                        this.fileList.push(item.content);
                    }
                }
                if (type === "video") {
                    this.currentMaterialVideo = JSON.parse(JSON.stringify(item));
                    this.videoDialogVisible = true;
                    this.materialTitle = this.$t('materialSelection.editVideo');
                    if (item.content.url !== "") {
                        this.fileList.push(item.content);
                    }
                }
                if (type === "file") {
                    this.currentMaterialFile = JSON.parse(JSON.stringify(item));
                    this.fileDialogVisible = true;
                    this.materialTitle = this.$t('materialSelection.editFile');
                    if (item.content.url !== "") {
                        this.fileList.push(item.content);
                    }
                }
                if (type === "voice") {
                    this.currentMaterialVoice = JSON.parse(JSON.stringify(item));
                    this.voiceDialogVisible = true;
                    this.materialTitle = this.$t('materialSelection.editVoice');
                    if (item.content.url !== "") {
                        this.fileList.push(item.content);
                    }
                }

            },
            viewImage(item) {
                console.log(item);
                this.previewImageUrl = item.content.url;
                this.showPreview = true;
            },
            saveCurrentText() {
                if (this.currentMaterialText.content.content === "") {
                    this.$message({
                        message: this.$t('materialSelection.textRequired'),
                        type: "warning",
                        duration: 2000,
                    });
                    return;
                }
                if (this.currentMaterialText.id == "") {
                    this.FetchPost("/dryer-api/material/textCheckWord", this.currentMaterialText.content).then(res => {
                        console.debug("create text response", res);
                        if (res.code == 0) {
                            this.$message({ message: this.$t('materialSelection.saveTextSuccess'), type: "success" });
                            this.loadMaterialList();
                            this.currentMaterialText.content = "";
                            this.textDialogVisible = false;
                        } else {
                            this.$message({ message: res.msg, type: "warning" });
                        }
                    });
                } else {
                    this.FetchPut("/dryer-api/material/textCheckWord/", this.currentMaterialText.id, this.currentMaterialText.content).then(res => {
                        console.debug("update text response", res);
                        if (res.code == 0) {
                            this.$message({ message: this.$t('materialSelection.updateTextSuccess'), type: "success" });
                            this.loadMaterialList();
                            this.currentMaterialText.content = "";
                            this.textDialogVisible = false;
                        } else {
                            this.$message({ message: res.msg, type: "warning" });
                        }
                    });
                }
            },
            saveCurrentNews() {
                console.log(this.currentMaterialNews);
                if (this.currentMaterialNews.content.title === "") {
                    this.$message({
                        message: this.$t('materialSelection.titleRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.currentMaterialNews.content.author === "") {
                    this.$message({
                        message: this.$t('materialSelection.authorRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.currentMaterialNews.content.content === "") {
                    this.$message({
                        message: this.$t('materialSelection.contentRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.currentMaterialNews.content.cover === "") {
                    this.$message({
                        message: this.$t('materialSelection.coverRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.currentMaterialNews.content.summary === "") {
                    this.$message({
                        message: this.$t('materialSelection.desRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.currentMaterialNews.id == "") {
                    this.FetchPost("/dryer-api/material/news-CheckWord", this.currentMaterialNews.content).then(res => {
                        console.debug("create news response", res);
                        if (res.code == 0) {
                            this.$message({ message: this.$t('materialSelection.saveImageText'), type: "success" });
                            this.loadMaterialList();
                            this.articleDrawer = false;
                        } else {
                            this.$message({ message: res.msg, type: "warning" });
                        }
                    });
                } else {
                    this.FetchPut("/dryer-api/material/news-CheckWord/", this.currentMaterialNews.id, this.currentMaterialNews.content).then(res => {
                        console.debug("create news response", res);
                        if (res.code == 0) {
                            this.$message({ message: this.$t('materialSelection.updateImageText'), type: "success" });
                            this.loadMaterialList();
                            this.articleDrawer = false;
                        } else {
                            this.$message({ message: res.msg, type: "warning" });
                        }
                    });
                }
            },
            //保存外链图文
            saveCurrentNewsLink() {
                var reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])/;
                if (this.currentMaterialLinkNews.content.title === "") {
                    this.$message({
                        message: this.$t('materialSelection.titleRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }

                if (this.currentMaterialLinkNews.content.url === "") {
                    this.$message({
                        message: this.$t('materialSelection.linkUrlRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (!reg.test(this.currentMaterialLinkNews.content.url)) {
                    this.$message({
                        message: this.$t('materialSelection.checkLinkUrl'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.currentMaterialLinkNews.content.cover === "") {
                    this.$message({
                        message: this.$t('materialSelection.coverRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                if (this.currentMaterialLinkNews.content.summary === "") {
                    this.$message({
                        message: this.$t('materialSelection.desRequired'),
                        duration: 2000,
                        type: "warning",
                    });
                    return;
                }
                console.log(this.currentMaterialLinkNews);
                if (this.currentMaterialLinkNews.id == "") {
                    this.FetchPost("/dryer-api/material/link-news-CheckWord", this.currentMaterialLinkNews.content).then(res => {
                        console.debug("create news response", res);
                        if (res.code == 0) {
                            this.$message({ message: this.$t('materialSelection.saveLinkUrl'), type: "success" });
                            this.loadMaterialList();
                            this.articleLinkDrawer = false;
                        } else {
                            this.$message({ message: res.msg, type: "warning" });
                        }
                    });
                } else {
                    this.FetchPut("/dryer-api/material/link-news-CheckWord/", this.currentMaterialLinkNews.id, this.currentMaterialLinkNews.content).then(res => {
                        console.debug("create news response", res);
                        if (res.code == 0) {
                            this.$message({ message: this.$t('materialSelection.updateLinkUrl'), type: "success" });
                            this.loadMaterialList();
                            this.articleLinkDrawer = false;
                        } else {
                            this.$message({ message: res.msg, type: "warning" });
                        }
                    });
                }
            },
            //图文上传校验
            aliyunOssRequestMaterialNews(data) {
                let file = data.file;
                console.log(file);
                const fileName = file.name;
                let size = file.size / 1024 / 1024 <= 2;
                const fileType = fileName.substring(fileName.lastIndexOf("."));
                console.log(fileType);
                if (fileType !== ".jpg" && fileType !== ".png" && fileType !== ".JPG" && fileType !== ".PNG") {
                    this.$message({
                        message: this.$t('materialSelection.uploadImageMsg'),
                        duration: 2000,
                        type: "warning",
                    });
                    this.fileList = [];
                    return;
                }
                if (!size) {
                    this.$message({
                        message: this.$t('materialSelection.uploadImageSize'),
                        type: "warning",
                        duration: 2000,
                    });
                    this.fileList = [];
                    return;
                }
                this.loadingUpload = true;
                let res = upload(ossConfig, file);
                console.debug("oss upload res", data, res);
                this.showUplodaBtn = false;

                return res;

            },
            aliyunOssRequestVoice(data) {
                let file = data.file;
                console.log(file);
                const fileName = file.name;
                const fileType = fileName.substring(fileName.lastIndexOf("."));
                if (fileType !== ".amr" && fileType !== ".mp3" && fileType !== ".AMR" && fileType !== ".MP3") {
                    this.$message({
                        message:this.$t('materialSelection.uploadVoiceMsg'),
                        duration: 2000,
                        type: "warning",
                    });
                    this.fileList = [];
                    return;
                }
                console.log(file);
                this.loadingUpload = true;
                let res = upload(ossConfig, file);
                console.debug("oss upload res", data, res);
                this.showUplodaBtn = false;
                return res;
            },
            aliyunOssRequestVideo(data) {
                let file = data.file;
                console.log(file);
                const fileName = file.name;
                let size = file.size / 1024 / 1024 <= 10;
                const fileType = fileName.substring(fileName.lastIndexOf("."));
                if (fileType !== ".mp4" && fileType !== ".MP4") {
                    this.$message({
                        message: this.$t('materialSelection.uploadVideoMsg'),
                        duration: 2000,
                        type: "warning",
                    });
                    this.fileList = [];
                    return;
                }
                if (!size) {
                    this.$message({
                        message: this.$t('materialSelection.uploadVideoSize'),
                        type: "warning",
                        duration: 2000,
                    });
                    this.fileList = [];
                    return;
                }
                console.log(file);
                this.loadingUpload = true;
                let res = upload(ossConfig, file);
                console.debug("oss upload res", data, res);
                this.showUplodaBtn = false;
                return res;

            },
            aliyunOssRequest(data) {
                let file = data.file;
                let size = file.size / 1024 / 1024 <= 20;
                console.log(file);
                if (!size) {
                    this.$message({
                        message: this.$t('materialSelection.uploadFileSize'),
                        type: "warning",
                        duration: 2000,
                    });
                    this.fileList = [];
                    return;
                }
                this.loadingUpload = true;
                let res = upload(ossConfig, file);
                console.debug("oss upload res", data, res);
                this.showUplodaBtn = false;
                return res;
            },
            loadMaterialList() {
                console.log("res", this.articalSearchInput);
                console.log("type", this.currentMaterialType);
                this.FetchGet("/dryer-api/material?type=" + this.currentMaterialType + "&keyword=" + this.articalSearchInput)
                    .then(res => {
                        console.debug("loadMaterialList res", res);
                        console.log("res", res);
                        this.currentMaterialList = res;
                        console.log("素材", this.currentMaterialList);
                    });
            },
            valueNull() {
                if (this.articalSearchInput === "") {
                    this.loadMaterialList();
                }
            },
            changShowOperation(index) {
                this.showTextOperation = index;
            },

            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.fileList = [];
                this.currentMaterialImage.content = {
                    name: "",
                    url: "",
                    size: 0,
                    ext: {},
                };
                this.currentMaterialVideo.content = {
                    name: "",
                    url: "",
                    description: "",
                    size: 0,
                    cover: "",
                    ext: {},
                };
                this.currentMaterialFile.content = {
                    name: "",
                    url: "",
                    size: 0,
                };
                this.currentMaterialVoice.content = {
                    name: "",
                    url: "",
                    ext: {},
                    size: 0,
                };
                this.showUplodaBtn = true;
            },
            playVideo(url) {
                this.dialogPlay = true;
                this.videoUrl = url;
            },
            closeDialog() {
                this.videoUrl = ""; //清空数据 关闭视频播放
            },
            closeDialogPic() {
                this.pictureDialogVisible = false;
                this.videoDialogVisible = false;
                this.fileDialogVisible = false;
                this.voiceDialogVisible = false;
                this.fileList = [];
            },
            listenAudio(index) {
                console.log(this.currentMaterialList[index]);
                if (this.playingStutus) {
                    this.$message.error("有音频正在播放，请先停止。");
                } else {
                    this.audio = new Audio();
                    // this.audio.src = 'https://img.tukuppt.com/newpreview_music/08/99/49/5c897788e421b53181.mp3';
                    this.audio.src = this.currentMaterialList[index].content.url;
                    let playPromise;
                    playPromise = this.audio.play();
                    let audioPlayButton = document.getElementById(index + "play");
                    console.log(audioPlayButton);
                    audioPlayButton.style.display = "none";
                    console.log(audioPlayButton.style.display);
                    let audioStopButton = document.getElementById(index + "stop");
                    audioStopButton.style.display = "flex";
                    this.playingStutus = true;
                    this.playIndex = index + "play";
                    let that = this;
                    /*if (playPromise) {
                                    playPromise.then(() => {
                                        // 音频加载成功
                                        // 音频的播放需要耗时
                                    that.tiemr = setInterval(() => {
                                        second--;
                                        if (second <= 0) {
                                        that.audio.pause()
                                        clearInterval(that.tiemr);
                                        }
                                    }, 1000);
                                    }).catch((e) => {
                                    // 音频加载失败
                                    console.error(e);
                                    });
                                }*/
                }
            },
            stopAudio(index) {
                this.audio.pause();
                let audioPlayButton = document.getElementById(index + "play");
                console.log(audioPlayButton);
                audioPlayButton.style.display = "block";
                let audioStopButton = document.getElementById(index + "stop");
                audioStopButton.style.display = "none";
                this.playingStutus = false;
                this.playIndex = index + "stop";
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //获取选中id改变index
            groupClick(index, tabName, item) {
                if (this.currentSelectedDiv == item.id) {
                    this.currentSelectedDiv = "null";
                    this.$emit("saveSelectedMaterial", {});
                } else {
                    this.currentSelectedDiv = item.id;
                    this.currentSelectedTabName = tabName;
                    console.log(item);
                    this.$emit("saveSelectedMaterial", item);
                }
                console.log(this.source);
            },
            //赋值图文链接
            copeUrl(itemUrl, item) {
                console.log(item);
                let that = this;
                let url = redirectUri[process.env.VUE_APP_CONFIG_ENV].massSend + "/article.html?id=" + item.id;
                window.open(url, "_blank");
                /*this.$copyText(url).then(function (e) {
                          that.$message({message: '复制成功', type: 'success' });
                      }, function (e) {
                          alert('您的浏览器不允许复制')
                      })*/

                /*this.$copyText(itemUrl).then(function (e) {
                          that.$message({message: '复制成功', type: 'success' });
                      }, function (e) {
                          alert('您的浏览器不允许复制')
                      })*/
                /* var input = document.createElement("input");   // js创建一个input输入框
                      input.value = this.text;  // 将需要复制的文本赋值到创建的input输入框中
                      document.body.appendChild(input);    // 将输入框暂时创建到实例里面
                      input.select();   // 选中输入框中的内容
                      document.execCommand("Copy");   // 执行复制操作
                      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作*/
            },
            copeLinkUrl(url, item) {
                window.open(url, "_blank");
            },
            //新建图文素材
            openMaterialNews() {
                this.articleDrawer = true;
                this.addMaterialTitle = this.$t('materialSelection.addImageText');
                this.currentMaterialNews = {
                    id: "",
                    content: {
                        author: "",
                        content: "",
                        cover: "",
                        ext: {},
                        summary: "",
                        title: "",
                        coverSize: "",
                    },
                };
                this.fileList = [];
            },
            openMaterialNewsLink() {
                this.articleLinkDrawer = true;
                this.addMaterialTitle = this.$t('materialSelection.addLinkUrl');
                this.fileList = [];
                this.currentMaterialLinkNews = {
                    id: "",
                    content: {
                        author: "",
                        cover: "",
                        coverSize: 0,
                        summary: "",
                        title: "",
                        url: "",
                    },
                };
            },
            //打开图文抽屉
            openarticleDrawer(item) {
                console.log(item);
                this.addMaterialTitle = this.$t('materialSelection.editImageText');
                this.currentMaterialNews = JSON.parse(JSON.stringify(item));
                this.articleDrawer = true;
                let info = {};
                this.fileList = [];
                info.url = item.content.cover;
                if (item.content.ext.name !== undefined) {
                    info.name = item.content.ext.name;
                }
                if (info.url !== "") {
                    this.fileList.push(info);
                }
                console.log("item", item.content.ext.name);
            },
            openMaterialNewsLinkDrawer(item) {
                this.articleLinkDrawer = true;
                this.addMaterialTitle = this.$t('materialSelection.editLinkUrl');
                this.currentMaterialLinkNews = JSON.parse(JSON.stringify(item));
                let info = {};
                this.fileList = [];
                info.url = item.content.cover;
                if (item.content.cover) {
                    let name = item.content.cover.substring(item.content.cover.lastIndexOf("/") + 1);
                    info.name = name;
                }
                if (info.url !== "") {
                    this.fileList.push(info);
                }
            },
            //打开新建文本抽屉
            openTextDialog() {
                this.textDialogVisible = true;
                this.materialTitle = this.$t('materialSelection.addText');
                this.currentMaterialText = {
                    id: "",
                    content: {
                        content: "",
                    },
                };
            },
            openPictureDialog() {
                this.fileList = [];
                this.pictureDialogVisible = true;
                this.materialTitle = this.$t('materialSelection.addImage');
                this.showUplodaBtn = true;
                this.loadingUpload = false;
                this.currentMaterialImage = {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        size: 0,
                        ext: {},
                    },
                };
            },
            openVideoDialog() {
                this.videoDialogVisible = true;
                this.materialTitle = this.$t('materialSelection.addVideo');
                this.fileList = [];
                this.showUplodaBtn = true;
                this.loadingUpload = false;
                this.currentMaterialVideo = {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        description: "",
                        size: 0,
                        cover: "",
                        ext: {},
                    },
                };
            },
            openFileDialog() {
                this.fileDialogVisible = true;
                this.materialTitle = this.$t('materialSelection.addFile');
                this.fileList = [];
                this.showUplodaBtn = true;
                this.loadingUpload = false;
                this.currentMaterialFile = {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        size: 0,
                    },
                };
            },
            openVoiceDialog() {
                this.voiceDialogVisible = true;
                this.materialTitle = this.$t('materialSelection.addVoice');
                this.fileList = [];
                this.showUplodaBtn = true;
                this.loadingUpload = false;
                this.currentMaterialVoice = {
                    id: "",
                    content: {
                        name: "",
                        url: "",
                        ext: {},
                        size: 0,
                    },
                };
            },
            //完成自定义图片
            finshedCustomImg() {
                this.customImageDialogVisible = false;
                console.debug("image", this.currentMaterialImage.content);
            },

        },
    };
</script>

<style lang="less">
    @import './../../../assets/less/main/common.less';

    .setWidth {
        /*width: calc(100vw - 64px);*/
    }

    .setHeight {
        height: calc(100vh - 480px) !important;
    }

    .addImage {
        .el-upload {
            display: flex;
        }

        .dialog-footer {
            display: flex;
            justify-content: flex-end;
        }
    }

    .material-box {
        .material-title {
            padding: 8px 16px;
            background: #f5f8fb;
            -webkit-box-shadow: 0 2px 10px -5px #d1dfec;
            box-shadow: 0 2px 10px -5px #d1dfec;
            border-bottom: 1px solid #d1dfec;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 16px;
                color: #303133;
                margin-left: 16px;
            }
        }

        .material-content {
            background-color: #ffffff;
            margin: 0px 14px 14px;
            height: calc(100vh - 145px);
            padding: 20px;
            .article-box {
                display: flex;
                flex-direction: column;

                .article-box-title {
                    max-width: 400px;
                }

                .article-box-content {
                    margin-top: 20px;
                    padding: 20px 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: calc(100vh - 260px);
                    overflow-x: hidden;
                    overflow-y: scroll;
                    align-content: flex-start;

                    .article-item-add {
                        margin: 5px 10px;
                        width: 160px;
                        height: 200px;
                        background-color: #366AFF;
                        padding: 20px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            color: #ffffff;
                            font-size: 36px;
                        }

                        span {
                            color: #ffffff;
                            margin-top: 10px;
                        }
                    }

                    .article-item {
                        margin: 5px 10px;
                        width: 200px;
                        height: 240px;
                        border: 1px solid #d1dfec;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        cursor: pointer;
                        text-align: left;

                        .article-item-image {
                            height: 100px;

                        }

                        .article-item-title {
                            margin: 5px 10px;
                            font-size: 13px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            line-clamp: 3;
                            -webkit-box-orient: vertical;
                            height: 40px;
                        }

                        .article-item-desc {
                            margin: 0px 10px;
                            color: #909399;
                            font-size: 12px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            line-clamp: 3;
                            -webkit-box-orient: vertical;
                            height: 50px;
                        }

                        .article-item-date {
                            margin: 10px 10px;
                            color: #909399;
                            font-size: 12px;
                        }

                        .article-item-operation {
                            z-index: 999;
                            background-color: rgba(0, 0, 0, 0.507);
                            padding: 10px 10px;
                            border-radius: 0px 0px 5px 5px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid #d1dfec;
                            position: relative;
                            bottom: -0px;

                            .date {
                                width: 50%;
                                font-size: 12px;
                                color: #ffffff;

                            }

                            .button {
                                cursor: pointer;

                                i {
                                    color: #ffffff;
                                    cursor: pointer;
                                    margin-left: 10px;
                                }
                            }

                        }
                    }

                    .article-item-selected {
                        border: 2px solid #366AFF;
                        /*   background: #ffffff url('./../../../assets/images/selected.png') no-repeat top right;*/
                    }
                }
            }

            .text-box {
                display: flex;
                flex-direction: column;

                .text-box-title {
                    max-width: 400px;
                }

                .text-box-content {
                    margin-top: 20px;
                    padding: 20px 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: calc(100vh - 260px);
                    overflow-x: hidden;
                    overflow-y: scroll;
                    align-content: flex-start;

                    .text-item-add {
                        margin: 5px 10px;
                        width: 160px;
                        height: 200px;
                        background-color: #366AFF;
                        padding: 20px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            color: #ffffff;
                            font-size: 36px;
                        }

                        span {
                            color: #ffffff;
                            margin-top: 10px;
                        }
                    }

                    .text-item-selected {
                        border: 2px solid #366AFF !important;
                        /* background: #ffffff url('./../../../assets/images/selected.png') no-repeat top right;*/
                    }

                    .text-item {
                        margin: 5px 10px;
                        width: 200px;
                        height: 240px;
                        border: 1px solid #d1dfec;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        text-align: left;

                        .text-item-title {
                            margin: 5px 10px;
                            font-size: 13px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            line-clamp: 6;
                            -webkit-box-orient: vertical;
                            height: 200px;
                        }

                        .text-item-operation {
                            z-index: 999;
                            background-color: rgba(0, 0, 0, 0.507);
                            padding: 10px 10px;
                            border-radius: 0px 0px 5px 5px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid #d1dfec;
                            position: relative;
                            bottom: -0px;

                            .date {
                                width: 50%;
                                font-size: 12px;
                                color: #ffffff;

                            }

                            .button {
                                cursor: pointer;

                                i {
                                    color: #ffffff;
                                    cursor: pointer;
                                    margin-left: 10px;
                                }
                            }

                        }
                    }
                }
            }

            // 图片素材
            .picture-box {
                display: flex;
                flex-direction: column;

                .picture-box-title {
                    max-width: 400px;
                }

                .picture-box-content {
                    margin-top: 20px;
                    padding: 20px 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: calc(100vh - 260px);
                    overflow-x: hidden;
                    overflow-y: scroll;
                    align-content: flex-start;

                    .picture-item-add {
                        margin: 5px 10px;
                        width: 160px;
                        height: 200px;
                        background-color: #366AFF;
                        padding: 20px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            color: #ffffff;
                            font-size: 36px;
                        }

                        span {
                            color: #ffffff;
                            margin-top: 10px;
                        }
                    }

                    .picture-item-selected {
                        border: 2px solid #366AFF !important;
                        /* background: #ffffff url('./../../../assets/images/selected.png') no-repeat top right;*/
                    }

                    .picture-item {
                        margin: 5px 10px;
                        width: 200px;
                        height: 240px;
                        border: 1px solid #d1dfec;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;

                        .picture-item-image {
                            height: 240px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .el-image {
                                border-radius: 5px 5px 0 0;
                            }

                        }

                        .picture-item-name {
                            margin: 10px 10px 10px 0px;
                            padding: 0px 10px;
                            font-size: 13px;
                            color: dimgray;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            text-align: left;
                        }

                        .picture-item-operation {
                            z-index: 999;
                            background-color: rgba(0, 0, 0, 0.507);
                            padding: 10px 10px;
                            border-radius: 0px 0px 5px 5px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid #d1dfec;
                            position: relative;
                            bottom: -0px;

                            .date {
                                width: 35%;
                                font-size: 12px;
                                color: #FFFFFF;

                            }

                            .button {
                                cursor: pointer;

                                i {
                                    color: #ffffff;
                                    cursor: pointer;
                                    margin-left: 10px;
                                }
                            }

                        }
                    }
                }
            }
        }

        // 添加编辑图文消息
        .template-config {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0px 20px;

            .template-config-box {
                background-color: #ffffff;
                border-radius: 5px;
                width: 70%;
                padding: 0px 10px;
              /*  overflow: hidden;*/

                .template-config-title {
                    padding: 0px 10px;
                    border-bottom: 1px solid #dddddd;
                    color: #303133;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .title-text {
                        width: 80%;
                    }

                    .link-title-text {
                        width: 100%;
                    }

                    .author {
                        width: 18%;
                    }

                    .el-input__inner {
                        border: none !important;
                        color: #2C3138;
                        font-size: 16px;
                        padding: 0px;
                        font-weight: 400;
                        padding-right: 45px;
                    }
                }

                .template-config-content {
                    margin: 20px 10px 10px 10px;
                    height: calc(100vh - 330px);

                    .ck-content {
                        height: calc(100vh - 350px);
                        word-break: break-all;
                        // ul,
                        // ol,
                        // li {
                        //     list-style-type: none;
                        // }
                        ul {
                            display: block !important;
                            list-style-type: disc !important;
                            margin-block-start: 1em !important;
                            margin-block-end: 1em !important;
                            margin-inline-start: 0px !important;
                            margin-inline-end: 0px !important;
                            padding-inline-start: 20px !important;
                        }

                        ul, ul li {
                            list-style-type: disc !important;
                        }

                        ol {
                            display: block !important;
                            list-style-type: decimal !important;
                            margin-block-start: 1em !important;
                            margin-block-end: 1em !important;
                            margin-inline-start: 0px !important;
                            margin-inline-end: 0px !important;
                            padding-inline-start: 20px !important;
                        }

                        ol, ol li {
                            list-style-type: decimal !important;
                        }
                    }
                }

                .template-config-other {
                    margin: 30px 10px 10px 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .desc {
                        width: 100%;

                        .el-input__inner {
                            padding: 0px 45px 0 15px;
                        }

                        .el-textarea__inner {
                            padding: 0px 45px 0 15px;
                        }
                    }

                    .image {
                        width: 100%;
                        height: 40px;
                        margin-bottom: 8px;
                        border: 1px darkgray dashed;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        .el-upload--picture-card {
                            width: 100%;
                            height: 41px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;

                            i {
                                font-size: 16px;
                            }
                        }

                        .el-upload {
                            height: 40px !important;
                            line-height: 40px;
                        }

                        .el-input__inner {
                            padding: 0px 15px;
                        }

                        .el-upload-list__item:first-child {
                            margin-top: 0 !important;
                        }
                    }

                    .el-upload-list__item-name {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: block;
                        margin-right: 40px;
                        max-width: 300px;
                    }
                }

                .template-link {
                    margin: 30px 10px 0 10px;
                }
            }

            .template-preview {
                background-color: #ffffff;
                border-radius: 5px;
                width: 320px;
                padding: 10px;
                flex: none;

                #material-right {
                    .el-tabs__nav-scroll {
                        height: auto !important;
                    }
                }

                .template-preview-content {
                    width: 316px;
                    height: 550px;
                    background: url('../../../assets/images/phone.png') no-repeat;
                    background-size: 316px 550px;
                    margin-bottom: 60px;

                    .template-preview-article-title {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px 5px;
                        margin: 0px 0px 0px 10px;
                        transform: scale(0.75, 0.75);
                        position: relative;
                        top: 40px;

                        i {
                            width: 3%;
                            margin-right: 20px;
                        }

                        span {
                            width: 90%;
                            margin-right: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .template-preview-message-content {
                        background-color: #ffffff;
                        // height: calc(100vh - 100px);
                        padding: 20px 15px;
                        margin: 0px 0px 0px 0px;
                        transform: scale(0.75, 0.75);
                        position: relative;
                        top: -40px;
                        height: 94%;
                        overflow-y: auto;
                        overflow-x: hidden;

                        .push-message-box {
                            border-radius: 5px;
                            border: 1px solid #dddddd;
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .image {
                                width: 100%;
                                height: 120px;
                            }

                            .title {
                                font-size: 16px;
                                font-weight: 500;
                                padding: 5px 15px;
                                word-break: break-all;
                                text-align: left;
                            }

                            .desc {
                                color: gray;
                                font-size: 13px;
                                padding: 5px 15px;
                                word-break: break-all;
                                text-align: left;
                            }
                        }
                    }

                    .template-preview-article-content {
                        background-color: #ffffff;
                        // height: calc(100vh - 100px);
                        padding: 20px 15px 20px 20px;
                        margin: 0px 0px 0px 0px;
                        transform: scale(0.75, 0.75);
                        position: relative;
                        top: -40px;
                        height: 94%;
                        overflow-y: auto;
                        overflow-x: hidden;

                        .article-content-title {
                            font-size: 16px;
                            font-weight: 500;
                            text-align: left;
                            word-break: break-all;
                        }

                        .article-content-author {
                            font-size: 14px;
                            font-weight: 500;
                            text-align: left;
                            margin: 10px 0px;
                            color: darkgray;

                            span {
                                margin-right: 15px;
                            }
                        }

                        .article-content-body-con {
                            p {
                                line-height: 30px;
                                text-indent: 20px;
                                margin-bottom: 10px;
                                text-align: left;
                            }

                            img {
                                width: 90%;
                            }

                            ul li {
                                list-style-type: initial;
                            }

                            ol li {
                                list-style-type: decimal;
                            }

                            .rich-text-li {
                                margin-left: 0px !important;
                            }
                        }
                    }

                    .card-box {
                        background-color: #2C3138;
                        border-radius: 10px;
                        box-shadow: 0 0 10px #999999;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 20px;
                        margin: 10px 10px 0px 10px;
                        transform: scale(0.75, 0.75);
                        position: relative;
                        top: 30px;
                        height: 30%;

                        .user-base-info {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            color: #F0D7B1;

                            .user-base-info-text {
                                display: flex;
                                flex-direction: column;

                                .name {
                                    font-size: 24px;
                                    margin-bottom: 10px;
                                }
                            }

                            .user-base-info-img {
                                width: 30%;

                                image {
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                        }

                        .user-more-info {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            margin-top: 10px;

                            .user-more-info-item {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                color: #aeb7bd;
                                padding: 4px 0px;
                                font-size: 9px;

                                i {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    .edit-box {
                        position: fixed;
                        top: 160px;
                        right: 0px;
                        background-color: #FFFFFF;
                        padding: 10px;
                        border-radius: 20px 0px 0px 20px;
                        display: none;
                        flex-direction: row;
                        align-items: center;

                        i {
                            margin-right: 5px;
                        }
                    }

                    .card-operation-box {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin: 0px 0px 10px 0px;
                        padding: 10px;
                        background-color: #FFFFFF;
                        height: 5%;
                        transform: scale(0.75, 0.75);

                        .card-operation-item {
                            display: flex;
                            flex-direction: row;
                            margin-left: 10px;
                            align-items: center;

                            image {
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                margin-left: -5px;
                                z-index: 2;
                            }

                            .count {
                                background-color: #E9E9EB;
                                height: 25px;
                                line-height: 25px;
                                padding: 0px 10px;
                                border-radius: 0px 10px 10px 0px;
                                margin-left: -5px;
                                z-index: 1;
                                color: #B2B2B2;
                            }
                        }

                        .share {
                            font-size: 14px;
                            color: #0F6EFF;

                            button {
                                border: #FFFFFF;
                                background-color: #0F6EFF;
                                color: #FFFFFF;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-size: 14px;
                                height: 25px;
                                border-radius: none;

                                i {
                                    font-size: 20px;
                                    margin-right: 5px;
                                }
                            }

                        }
                    }

                    .chatbot-box {
                        display: flex;
                        flex-direction: column;
                        z-index: 999;
                        //background-color: #FFFFFF;
                        padding: 5px 20px 20px 20px;
                        transform: scale(0.75, 0.75);
                        height: 70%;
                        position: relative;
                        top: -60px;

                        .chatbot-title {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            padding: 0px 0px 10px 0px;
                            border-bottom: 1px solid #BCBEC2;
                        }

                        .chatbot-content {
                            margin: 10px 0px;
                            height: 66%;
                            overflow-y: auto;
                            overflow-x: hidden;

                            .message-box {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                margin: 10px 0px;

                                .chatbot-message {
                                    background-color: #FFFFFF;
                                    padding: 10px;
                                    border: 1px solid #E9E9EB;
                                    border-radius: 0px 20px 20px 20px;
                                    max-width: 70vw;
                                }
                            }

                            .message-box-user {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;

                                .user-message {
                                    background-color: #0F6EFF;
                                    color: #FFFFFF;
                                    padding: 10px;
                                    border: 1px solid #E9E9EB;
                                    border-radius: 20px 0px 20px 20px;
                                    max-width: 70vw;
                                }
                            }
                        }
                    }

                    .customer-followup-add {
                        position: fixed;
                        bottom: 0px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: #0F6EFF;
                        border-radius: 50%;
                        padding: 10px;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        z-index: 1000;

                        i {
                            font-size: 28px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }

        .article-template-footer {
            position: absolute;
            bottom: 0px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-bottom: 20px;
            background-color: white;

            .el-button {
                width: 40%;
            }
        }
    }

    // 图文编辑抽屉
    .el-drawer__header {
        margin-bottom: 0px !important;
        border-bottom: none !important;
        padding-bottom: 10px;
    }

    .file-list-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 10px;

        .file-name {
            text-align: left;
            font-size: 10px;
        }

        .file-status {
            text-align: right;
            margin-left: 20px;
            font-size: 10px;
        }
    }

    .file-list-box:hover {
        background-color: #dddddd;
        padding: 0px 10px;
        border-radius: 5px;
    }

    #material-nav {
        .el-tabs__nav-scroll {
            height: auto !important;
        }

        .el-tabs__nav {
            .el-tabs__item {
                justify-content: none !important;
            }
        }
    }

    .custom-dialog {
        .el-dialog__header {
            padding: 0;
        }

        .el-dialog__body {
            padding: 16px 0 0 0 !important;
        }

        .custom-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 2px 2px 5px gray;
            height: 60px;
            padding: 0 20px;

        }

        .el-dialog__headerbtn .el-dialog__close {
            display: none !important;
        }
    }
</style>
