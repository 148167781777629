<template>
    <div class="page-top-header">
        <slot name="top-header-left"></slot>
        <div class="right">
            <slot name="top-header-right"></slot>
            <div class="top-header-right">
                <div v-if="!isShowTest && !isShowHealty && uid != 20281 &&  uid != 117431" class="new-help-tips" @click="gotoHelp">
                    <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                    <span>{{$t('common.helpCenter')}}</span>
                </div>
                <div v-if="(isShowTest && !isShowHealty) || (!isShowTest && isShowHealty)" class="new-help-tips-min" @click="gotoHelp">
                    <i class="iconfont guoran-tongyichicun-xinshouyindao"></i>
                    <!-- <span>帮助中心</span> -->
                </div>
            </div>
            <div class="user-icon">
                <el-dropdown @command="topHandleCommand">
                        <span class="el-dropdown-link">
                            <!-- <span class="head_portrait">
                                <el-avatar :size="37" :src="userInfo.profilePhoto|filterProfilePhoto"></el-avatar>
                            </span> -->
                            <el-image style="width:37px;height:37px;border-radius:50%" fit="cover" :src="userInfo.profilePhoto|filterProfilePhoto"></el-image>
                        </span>
                        <el-dropdown-menu slot="dropdown" style="min-width:150px;">
                            <el-dropdown-item
                                    style="color: black;word-break: break-word;border-bottom: 2px solid #f5f8fb;line-height: 30px">
                                {{userInfo.realName?userInfo.realName:userInfo.username?userInfo.username:""}}
                            </el-dropdown-item>
                            <el-dropdown-item command="a" class="borderBottom">
                                <i class="el-icon-user-solid" style="font-size: 14px;"></i>{{$t('common.personalInformation')}}
                            </el-dropdown-item>
                            <!-- <el-dropdown-item command="business" class="borderBottom"
                                              v-if="roles === 1 || roles === 6">
                                <i class="el-icon-office-building"
                                   style="font-size: 14px;"></i>企业管理
                            </el-dropdown-item> -->
                            <el-dropdown-item command="logout">
                                <i class="el-icon-switch-button"
                                   style="font-size: 14px;"></i>{{$t('common.logOut')}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                </el-dropdown>
            </div>
           
        </div>
    </div>
</template>

<script>
import { rediect } from "@/const/rediect";
import { redirect2LoginPage } from "@/const/redirectUri";
export default {
    props:{
		headerTitle:{
			type:String,
			default:''
		}
	},
    data(){
        return {
            isShowTest: false,
            userInfo:{},
            roles: "",
            isShowHealty:false,
            uid:"", 
        }
    },
    mounted(){
        this.uid = localStorage.getItem('_uid')
        this.$nextTick(()=>{
            setTimeout(()=>{
                let userInfo = localStorage.getItem('userInfo');
                if (userInfo && userInfo!=='{}'){
                    this.userInfo = JSON.parse(userInfo);
                    this.roles = this.userInfo.roleId;
                }
            },500)
        })
        this.$eventBus.$on("test-window-visible", (visible) => {
            this.isShowTest = visible;
        });
        this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
        
        this.$eventBus.$on("bot-heathly-visible", (visible) => {
            this.isShowHealty = visible.flag;
        })
        this.isShowHealty = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
        
    },
    filters:{
        filterProfilePhoto(val) {
            let str = val;;
            if (val == '' || val == null || val == 'null') {
                str = "https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png"; 
            } 
            return str;
        }
    },
    methods:{
        getUserInfo() {
            let uid = localStorage.getItem("_uid");
            this.FetchGet("/portal-api/account/appoint/" + uid).then(res => {
                if (res.code === "0") {
                    this.userInfo = res.data;
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    setTimeout(()=>{
                        let userInfo = localStorage.getItem('userInfo');
                        if (userInfo && userInfo!=='{}'){
                            this.userInfo = JSON.parse(userInfo);
                            this.roles = this.userInfo.roleId;
                        }
                    },500)
                }
            });
        },
        topHandleCommand(command) {
            if (command === "logout") {
                localStorage.removeItem("token");
                this.FetchDelete2(this.requestUrl.auth.ssoLogout, {}).then(res => {
                    if (res.code === "0") {
                        redirect2LoginPage();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
            if (command === "a") {
                this.$router.push({ path: "personal" });
            }
            if (command === "business") {
                let windo = window.open("_blank");
                windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].portal;
                windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].test;
            }
            if (command === "change") {
                /* let windo= window.open('_blank')
                    windo.location = rediect[process.env.VUE_APP_CONFIG_ENV].portal;*/
                console.log(rediect[process.env.VUE_APP_CONFIG_ENV].portal);
                window.open(rediect[process.env.VUE_APP_CONFIG_ENV].portal);
            }
        },
         // 跳转到帮助页面
        gotoHelp() {
            //window.open("https://showdoc.askbot.cn/web/#/5?page_id=24");
            this.$router.push({
                name: "help",
                params: {
                    helpSrc:
                        "https://showdoc.askbot.cn/web/#/readonly/5?page_id=24",
                },
            });
        },
    }
    
}
</script>

<style lang="less" scoped>
.page-top-header{
    display: flex;
    height: 62px;
    background: #fff;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    margin: 14px 14px 16px 14px;
    overflow: hidden;
    // width: 100%;
    // min-width: 980px;
    font-size: 16px;
    color: #000000;
    .top-header-left{
        display: flex;
        align-items: center;
        i{
            font-size: 20px;
            margin-right: 10px;
        }
        .page-name{
            margin-right: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
        }
    }
    .right{
        display: flex;
        align-items: center;
        .top-header-right{
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .user-icon{
            margin-left: 10px;
            margin-top: 5px;
            cursor: pointer;
        }
        .new-help-tips-min {
            width: 33px;
            height: 33px;
            background: #ffffff;
            border: 1px solid #a1b9ff;
            border-radius: 17px;
            font-size: 14px;
            color: #366aff;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-align: center;
            i {
                font-size: 10px;
                color: #fff;
                margin: 0 auto;
                width: 20px;
                height: 20px;
                background: linear-gradient(224deg, #749bff, #366aff);
                border-radius: 50%;
                display: inline-block;
                line-height: 20px;
                text-align: center;
            }
        }
        .new-help-tips {
            //width: 80px;
            padding: 0 10px 0 6px;
            height: 31px;
            background: #ffffff;
            border: 1px solid #a1b9ff;
            border-radius: 17px;
            font-size: 14px;
            color: #366aff;
            cursor: pointer;
            display: flex;
            align-items: center;
            i {
                font-size: 10px;
                color: #fff;
                margin-right: 4px;
                width: 20px;
                height: 20px;
                background: linear-gradient(224deg, #749bff, #366aff);
                border-radius: 50%;
                display: inline-block;
                line-height: 20px;
                text-align: center;
            }
        }
    }
}

</style>